<template>
  <div class="flex-grow h-full">
    <div class="px-4 text-lg space-y-4">
      <div>
        <GenericAutoComplete
          v-model="problem"
          :readonly="review.isPlanReadonly.value"
          placeholder="select a problem"
          label="Problem"
          :items="problems"
          :item-options="{
            displayProperty: 'text',
            filterProperties: ['text', 'value'],
            valueProperty: 'value',
          }"
        />
      </div>

      <div v-if="problem" class="space-x-4">
        <span>Specify {{ problem }}</span>
        <input
          v-model="problemName"
          type="text"
          class="flex-1 focus:outline-none border-b-2 min-w-[750px] py-2 transition-colors max-w-full"
          :class="[isFocused ? 'border-black' : 'border-black/30']"
          :placeholder="`type ${problem} name`"
          @focus="isFocused = true"
          @blur="isFocused = false"
        />
      </div>
      <div v-if="problemName">
        <GenericAutoComplete
          v-model="purpose"
          :readonly="review.isPlanReadonly.value"
          placeholder="select a purpose"
          label="Purpose"
          :items="purposeOptions"
          :item-options="{
            displayProperty: 'text',
            filterProperties: ['text', 'value'],
            valueProperty: 'value',
          }"
        />
      </div>
      <div class="flex justify-end w-full right-0">
        <button
          v-if="purpose"
          class="bg-primary text-white px-4 py-2 hover:brightness-95 rounded-lg"
          @click.stop="applyPreset"
        >
          Apply preset
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ref, watch } from 'vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import useLoading from '@app/composables/use-loading'

const review = injectStrict(ReviewKey)

const isFocused = ref()
const purpose = ref()
const purposeOptions = ref<{ text: string; value: string }[]>([])

function generatePreset(problemName: string, purpose: string) {
  return [
    {
      label: 'Definition',
      question:
        'What is the clinical or medical definition of ' + problemName + '?',
      order: 1,
    },
    {
      label: 'Coding',
      question:
        'What is the medical code, ICD-10 or ICD-11 code for ' +
        problemName +
        '?',
      order: 2,
    },
    {
      label: 'Alternative names',
      question:
        'Are there any other medical or common names for ' + problemName + '?',
      order: 3,
    },
    {
      label: 'Grading',

      question:
        'Is there a standardized grading system to assess the severity of ' +
        problemName +
        '?',
      order: 4,
    },
    {
      label: 'Incidence',
      question:
        'What is the incidence rate or frequency of ' + problemName + '?',
      order: 5,
    },
    {
      label: 'Prevalence',
      question:
        'What is the prevalence rate or number of cases of ' +
        problemName +
        ' in a given population?',
      order: 6,
    },

    {
      label: 'Age considerations',
      question: 'How does age impact ' + problemName + '?',
      order: 7,
    },

    {
      label: 'Gender considerations',
      question: 'How does gender influence ' + problemName + '?',
      order: 8,
    },

    {
      label: 'Race considerations',
      question: 'What is the effect of race on ' + problemName + '?',
      order: 9,
    },

    {
      label: 'Ethnicity considerations',
      question: 'How does ethnicity impact ' + problemName + '?',
      order: 10,
    },

    {
      label: 'Environmental considerations',
      question: 'Do environmental factors affect ' + problemName + '?',
      order: 11,
    },

    {
      label: 'Cultural factors',
      question: 'What role do cultural factors play in ' + problemName + '?',
      order: 12,
    },
    {
      label: 'Lifestyle factors',
      question: 'Do lifestyle factors contribute to ' + problemName + '?',
      order: 13,
    },

    {
      label: 'Risk factors',
      question:
        'What are the recognized risk factors associated with ' +
        problemName +
        '?',
      order: 14,
    },

    {
      label: 'Natural course and typical complications',
      question:
        'What is the typical natural course of ' +
        problemName +
        ' and what are its common complications?',
      order: 15,
    },
    {
      label: 'Relevant clinical outcomes and assessment tools',
      question:
        'What are the relevant clinical outcomes for evaluating  ' +
        problemName +
        ' and what assessment tools are commonly used?',
      order: 16,
    },

    {
      label: `Alternative ${purpose} options`,
      question: `What are the available ${purpose} options for ${problemName}? Which treatment options are considered outdated, standard of care, novel, first-in-line, second-in-line, supportive/adjuvant, gold-standard? Which treatment options can be combined?`,
      order: 17,
    },
  ]
}
const problemName = ref()

const problem = ref()
const loading = useLoading()

async function applyPreset() {
  const presets = generatePreset(problemName.value, purpose.value)

  try {
    loading.start()
    await review.addMultipleAttributesStructure(review.entity.value.id, presets)
  } catch (error) {
    console.error('Error adding scope field:', error)
  } finally {
    loading.stop()
  }

  problem.value = ''
  problemName.value = ''
  purpose.value = ''
}

const problems = [
  {
    text: 'Disease',
    value: 'disease',
  },
  {
    text: 'Injury or Disability',
    value: 'injury or disability',
  },
  {
    text: 'Anatomy of physiological or Pathological process or state',
    value: 'anatomy of physiological or pathological process or state',
  },
]

interface ProblemOptions {
  disease: { text: string; value: string }[]
  'injury or disability': { text: string; value: string }[]
  'anatomy of physiological or pathological process or state': {
    text: string
    value: string
  }[]
}

const problemOptions: ProblemOptions = {
  disease: [
    { text: 'Treatment', value: 'treatment' },
    { text: 'Alleviation', value: 'alleviation' },
    { text: 'Diagnosis', value: 'diagnosis' },
    { text: 'Prevention', value: 'prevention' },
    { text: 'Monitoring', value: 'monitoring' },
    { text: 'Prediction', value: 'prediction' },
    { text: 'Prognosis', value: 'prognosis' },
  ],
  'injury or disability': [
    { text: 'Treatment', value: 'treatment' },
    { text: 'Alleviation', value: 'alleviation' },
    { text: 'Diagnosis', value: 'diagnosis' },
    { text: 'Monitoring', value: 'monitoring' },
    { text: 'Compensation', value: 'compensation' },
  ],

  'anatomy of physiological or pathological process or state': [
    { text: 'Investigation', value: 'investigation' },
    { text: 'Replacement', value: 'replacement' },
    { text: 'Modification', value: 'modification' },
  ],
}

watch(
  () => problemName.value,
  (newProblemName: string) => {
    if (newProblemName.length === 0) {
      purpose.value = ''
    } else {
      const selectedProblem = problem.value as keyof ProblemOptions
      purposeOptions.value = selectedProblem
        ? problemOptions[selectedProblem] || []
        : []
    }
  },
)
</script>
