<template>
  <ImportSourceCard
    :import-source="pmc"
    :tooltip="{
      text: 'use the Evidence browser extension to import studies from PMC',
      url: '/docs/reviews.html#pmc',
    }"
    icon-url="/sources-icons/pmc.png"
    @import-source-removed="emit('importSourceRemoved')"
  >
  </ImportSourceCard>
</template>
<script setup lang="ts">
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import ImportSourceCard from '../Components/ImportSourceCard.vue'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
const builtInImportSources = useBuiltInImportSources()
const pmc = builtInImportSources.findById(BuiltInImportSourceId.PMC)!
const emit = defineEmits<(e: 'importSourceRemoved') => void>()
</script>
