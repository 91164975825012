<template>
  <ImportSourceCard
    :import-source="mdcg"
    :tooltip="{
      text: 'use the Evidence browser extension to import MDCG guidance from Medical Device Coordination Group',
      url: '/docs/reviews.html#mdcg',
    }"
    @import-source-removed="emit('importSourceRemoved')"
  >
  </ImportSourceCard>
</template>
<script setup lang="ts">
import ImportSourceCard from '../Components/ImportSourceCard.vue'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'

const builtInImportSources = useBuiltInImportSources()

const mdcg = builtInImportSources.findById(BuiltInImportSourceId.MDCG)!
const emit = defineEmits<(e: 'importSourceRemoved') => void>()
</script>
