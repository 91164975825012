<template>
  <OverflowNavigation
    ref="overflowNavigation"
    v-slot="{ reloadNavigation }"
    sidebar-width="300px"
  >
    <div class="py-4 px-4 relative">
      <h3
        class="text-2xl text-primary font-medium flex items-center gap-2 mb-4"
      >
        Search
      </h3>

      <div class="flex flex-col gap-4 pb-4">
        <TransitionGroup name="flash">
          <div
            v-for="importSource in importSources"
            :key="importSource?.id"
            class="break-inside-avoid rounded-md"
          >
            <PubmedImport
              v-if="importSource?.id === BuiltInImportSourceId.PUBMED"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <OpenAlexImport
              v-else-if="importSource?.id === BuiltInImportSourceId.OPEN_ALEX"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <GoogleScholarImport
              v-else-if="
                importSource?.id === BuiltInImportSourceId.GOOGLE_SCHOLAR
              "
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <DimensionsAiImport
              v-else-if="
                importSource?.id === BuiltInImportSourceId.DIMENSIONS_AI
              "
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <MdcgImport
              v-else-if="importSource?.id === BuiltInImportSourceId.MDCG"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <CochraneImport
              v-else-if="importSource?.id === BuiltInImportSourceId.COCHRANE"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <MaudeImport
              v-else-if="importSource?.id === BuiltInImportSourceId.MAUDE"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <OpenFdaImport
              v-else-if="importSource?.id === BuiltInImportSourceId.OPEN_FDA"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <IctrpImport
              v-else-if="importSource?.id === BuiltInImportSourceId.ICTRP"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <PMCImport
              v-else-if="importSource?.id === BuiltInImportSourceId.PMC"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <BfarmImport
              v-else-if="importSource?.id === BuiltInImportSourceId.BFARM"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <CenelecImport
              v-else-if="importSource?.id === BuiltInImportSourceId.CENELEC"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <HandSearch
              v-else-if="importSource?.id === BuiltInImportSourceId.HAND_SEARCH"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <CitationSearch
              v-else-if="
                importSource?.id === BuiltInImportSourceId.CITATION_SEARCH
              "
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
            <ImportFromCustomSource
              v-else
              :import-source="importSource"
              @import-source-removed="onSourceEvent(reloadNavigation)"
            />
          </div>
        </TransitionGroup>
      </div>
      <div class="">
        <div key="addSource" class="w-full">
          <AddImportSourceToPlan @import-source-added="reloadNavigation" />
        </div>
      </div>
    </div>
  </OverflowNavigation>
</template>
<script setup lang="ts">
import PubmedImport from './Pubmed/PubmedImport.vue'
import GoogleScholarImport from './GoogleScholar/GoogleScholarImport.vue'
import DimensionsAiImport from './DimensionsAI/DimensionsAiImport.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../use-review'
import { computed } from 'vue'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import ImportFromCustomSource from './CustomImportSource/ImportFromCustomSource.vue'

import AddImportSourceToPlan from './AddImportSourceToPlan/AddImportSourceToPlan.vue'
import HandSearch from './HandSearch/HandSearch.vue'
import CitationSearch from './CitationSearch/CitationSearch.vue'
import CochraneImport from './Cochrane/CochraneImport.vue'
import MaudeImport from './Maude/MaudeImport.vue'
import OpenAlexImport from './OpenAlex/OpenAlexImport.vue'
import MdcgImport from './MDCG/MdcgImport.vue'
import OpenFdaImport from './OpenFDA/OpenFdaImport.vue'
import IctrpImport from './ICTRP/IctrpImport.vue'
import PMCImport from './PMC/PMCImport.vue'
import BfarmImport from './Bfarm/BfarmImport.vue'
import CenelecImport from './CENELEC/CenelecImport.vue'
import OverflowNavigation from '@app/components/Global/OverflowNavigation.vue'

function onSourceEvent(reloadNavigation: () => void) {
  setTimeout(reloadNavigation, 700)
}
const review = injectStrict(ReviewKey)

const importSources = computed(() => {
  return [...(review.entity.value.plan?.importPlan.importSources ?? [])].sort(
    (a, b) => {
      const aLength = review.searchesBySource.value?.[a.id]?.length || 0
      const bLength = review.searchesBySource.value?.[b.id]?.length || 0

      if (aLength > 0 && bLength === 0) {
        return -1
      } else if (aLength === 0 && bLength > 0) {
        return 1
      } else {
        return 0
      }
    },
  )
})
</script>
<style>
.flash-enter-active {
  animation: flash-background 1s;
}

@keyframes flash-background {
  0% {
    background-color: transparent;
  }

  50% {
    @apply bg-green-100;
  }

  100% {
    background-color: transparent;
  }
}

.flash-leave-active {
  animation: fade-out-background 0.2s;
}

@keyframes fade-out-background {
  100% {
    opacity: 0;
  }
}
</style>
