<template>
  <div class="h-full flex flex-col overflow-hidden">
    <div class="flex px-8 border-b flex-col gap-6 w-full sticky top-0 z-50">
      <div class="dark:bg-[#103047] bg-white z-50 space-y-6 py-4">
        <h2
          class="text-lg pb-4 font-bold text-primary dark:text-white flex items-center gap-2"
        >
          Assign members to project
          <FloatingMenu>
            <template #activator="{ show }">
              <button class="block" @click="show">
                <InformationCircleIcon class="w-5 h-5 text-black/80" />
              </button>
            </template>
            <template #content>
              <div class="w-64 p-2">
                <p>Assign users and external members to the project.</p>
                <div>
                  <a
                    class="text-primary underline"
                    target="_blank"
                    href="/docs/projects.html#assigning-members-to-a-project"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </template>
          </FloatingMenu>
        </h2>
        <div class="flex flex-col gap-4 bg-white dark:bg-[#103047]">
          <div class="flex items-center gap-4">
            <ProjectMembers :project="project" />

            <div class="w-full">
              <Scrollbar class="pb-3.5">
                <div class="w-full flex gap-4">
                  <template
                    v-for="user in project.users"
                    :key="'user-' + user.id"
                  >
                    <Draggable
                      :id="user?.id"
                      v-slot="{ isDragged }"
                      @update:is-dragged="
                        updateDraggedItem(
                          user?.id,
                          DraggedItemType.member,
                          $event,
                        )
                      "
                    >
                      <div
                        class="rounded-full dark:text-white text-black text-center max-w-fit border-2 py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                        :class="{
                          'border-white text-white': isDragged,
                          'border-transparent': !isDragged,
                        }"
                        :style="{
                          'background-color': `${
                            usersColors[user?.id ?? '']
                          }40`,
                        }"
                      >
                        <div
                          class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                          :style="{
                            backgroundColor: usersColors[user?.id ?? ''],
                          }"
                        >
                          <p class="h-3 w-3 font-medium leading-3">
                            {{ buildUserFullname(user)[0].toUpperCase() }}
                          </p>
                        </div>
                        <div class="leading-none">
                          {{ buildUserFullname(user) }}
                        </div>
                        <div class="w-5 h-5 flex items-center justify-center">
                          <button
                            v-tooltip="
                              !canDeleteMember()
                                ? 'Cannot remove member. Assigned in some reviews.'
                                : ''
                            "
                            :class="{
                              'opacity-50 cursor-not-allowed':
                                !canDeleteMember(),
                            }"
                            class="hover:bg-transparent"
                            @click.stop="
                              removeProjectMember(project.id!, user?.id)
                            "
                          >
                            <XMarkIcon class="h-[16.5px]" />
                          </button>
                        </div>
                      </div>
                    </Draggable>
                  </template>
                </div>
              </Scrollbar>
            </div>
          </div>

          <div class="flex items-center gap-4">
            <ProjectExternalMembers :project-id="project.id!" />
            <div class="w-full">
              <Scrollbar class="pb-3.5">
                <div class="flex gap-4">
                  <template
                    v-for="externalMember in project.externalMembers"
                    :key="'external-member-' + externalMember.id"
                  >
                    <Draggable
                      :id="externalMember.id"
                      v-slot="{ isDragged }"
                      @update:is-dragged="
                        updateDraggedItem(
                          externalMember.id,
                          DraggedItemType.externalMember,
                          $event,
                        )
                      "
                    >
                      <div
                        class="bg-[#D8DCDE] rounded-full text-black text-center max-w-fit border-2 py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                        :class="{
                          'border-white text-white': isDragged,
                          'border-transparent': !isDragged,
                        }"
                      >
                        <div
                          class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center bg-[#04517066]"
                        >
                          <p class="h-3 w-3 font-medium leading-3 text-white">
                            {{ externalMember.name[0].toUpperCase() }}
                          </p>
                        </div>

                        <div class="leading-none">
                          {{ externalMember.name }}
                        </div>
                        <div class="w-5 h-5 flex items-center justify-center">
                          <button
                            v-tooltip="
                              !canDeleteExternalMember()
                                ? 'Cannot remove external Member. This member is assigned as an external approver in some reviews.'
                                : ''
                            "
                            class="hover:bg-transparent"
                            :class="{
                              'opacity-50 cursor-not-allowed':
                                !canDeleteExternalMember(),
                            }"
                            @click.stop="
                              removeProjectExternalMember(
                                project.id!,
                                externalMember.id,
                              )
                            "
                          >
                            <XMarkIcon class="h-[16.5px]" />
                          </button>
                        </div>
                      </div>
                    </Draggable>
                  </template>
                </div>
              </Scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-y-hidden">
      <div class="h-full w-full flex">
        <Scrollbar class="w-full px-8 py-4 h-full">
          <h2
            class="text-lg pb-4 font-bold text-primary dark:text-white flex items-center gap-2"
          >
            Assign members to reviews
            <FloatingMenu>
              <template #activator="{ show }">
                <button class="block" @click="show">
                  <InformationCircleIcon class="w-5 h-5 text-black/80" />
                </button>
              </template>
              <template #content>
                <div class="w-64 p-2">
                  <p>Assign members to the different project's reviews</p>
                  <div>
                    <a
                      class="text-primary underline"
                      target="_blank"
                      href="/docs/projects.html#assigning-members-to-a-project-s-reviews"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </template>
            </FloatingMenu>
          </h2>
          <div class="flex flex-wrap gap-8 pb-8">
            <div
              v-for="review in project.reviews"
              :key="'review-' + review.id"
              class="flex flex-col gap-4 p-4 bg-black/5 rounded-2xl dark:bg-faded/20 w-[284px] border dark:border-0 relative"
            >
              <div
                v-if="review.isArchived || review.isLocked"
                class="absolute top-0 left-0 w-full h-full bg-black/10 z-50"
              />
              <div class="truncate text-black dark:text-white">
                <div class="flex gap-1">
                  {{ review.name }}
                  <LockClosedIcon
                    v-if="review.isArchived || review.isLocked"
                    class="w-4 text-red-600"
                  />
                </div>
              </div>
              <div class="space-y-4">
                <div>
                  <ReviewMemberAssignment
                    :project="project"
                    :review-id="review.id"
                    :role="ReviewRole.AUTHOR"
                  >
                    <template #activator="{ show }">
                      <div
                        class="flex flex-col justify-stretch border px-2 py-4 rounded-lg relative bg-white dark:bg-faded/20 dark:border-0 border-[#CCCCCC] text-black dark:text-white"
                        :class="{ 'border-transparent': draggedItem.id }"
                      >
                        <div
                          class="flex justify-between items-center mb-4 text-primary"
                        >
                          <div class="flex gap-1 items-center">
                            <MiniUserIcon
                              class="w-5 h-5"
                              :class="[
                                draggedItem.id &&
                                !isMemberAlreadyAssignedToAuthors(
                                  draggedItem.id,
                                  review.id,
                                ) &&
                                draggedItem.type === DraggedItemType.member
                                  ? 'fill-green-500'
                                  : 'fill-primary',
                              ]"
                            />
                            <p class="font-medium text-sm text-black">
                              Authors
                            </p>
                          </div>
                          <div
                            v-tooltip="'Click to add author'"
                            class="flex px-2 py-1 gap-1 hover:cursor-pointer items-center rounded-lg hover:bg-[#F2F6F8]"
                            @click="show"
                          >
                            <PlusIcon class="w-4 h-4 fill-primary" />
                            <p class="font-normal text-xs leading-[18px]">
                              Add authors
                            </p>
                          </div>
                        </div>

                        <div class="flex flex-wrap justify-start gap-2">
                          <div
                            v-for="author in review.authors"
                            :key="'author-' + author?.id"
                            v-tooltip="author?.email"
                          >
                            <div
                              class="max-w-fit rounded-full text-center border-2 border-transparent py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap"
                              :style="{
                                'background-color': `${
                                  usersColors[author?.id ?? '']
                                }40`,
                              }"
                            >
                              <div
                                class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                                :style="{
                                  backgroundColor:
                                    usersColors[author?.id ?? ''],
                                }"
                              >
                                <p class="h-3 w-3 font-medium leading-3">
                                  {{ author?.email[0].toUpperCase() }}
                                </p>
                              </div>
                              <div class="leading-none truncate max-w-[48px]">
                                {{ author?.email }}
                              </div>
                              <div
                                class="w-2.5 h-2.5 flex items-center justify-center"
                              >
                                <button
                                  class="hover:bg-transparent"
                                  @click.stop="
                                    removeAuthorFromReview(
                                      review.id,
                                      author?.id!,
                                    )
                                  "
                                >
                                  <XMarkIcon class="h-[16.5px]" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <template v-if="draggedItem.id">
                          <div
                            v-if="
                              isMemberAlreadyAssignedToAuthors(
                                draggedItem.id,
                                review.id,
                              ) || draggedItem.type !== DraggedItemType.member
                            "
                            class="absolute top-0 left-0 w-full h-full rounded-lg bg-white bg-opacity-40 border border-solid border-[#D9E4EA]"
                          />
                          <DropZone
                            v-else
                            v-slot="{ isDraggedOver }"
                            @dropped="addAuthorToReview(review.id, $event)"
                          >
                            <div
                              class="absolute rounded-lg top-0 left-0 w-full h-full flex items-start justify-end px-2 py-[18px]"
                              :class="{
                                'bg-green-500 opacity-90': isDraggedOver,
                                'border-2 border-dashed !border-green-500 bg-green-500/10':
                                  !isDraggedOver,
                              }"
                            >
                              <div
                                v-if="isDraggedOver"
                                class="flex gap-1 items-center text-white pointer-events-none"
                              >
                                <UserPlusIcon class="w-4 h-4 fill-white" />
                                <p class="font-medium text-sm text-white">
                                  Assign Author
                                </p>
                              </div>
                            </div>
                          </DropZone>
                        </template>
                      </div></template
                    >
                  </ReviewMemberAssignment>
                </div>
                <div>
                  <ReviewMemberAssignment
                    :project="project"
                    :review-id="review.id"
                    :role="ReviewRole.REVIEWER"
                  >
                    <template #activator="{ show }">
                      <div
                        class="flex flex-col justify-stretch border px-2 py-4 relative bg-white border-[#CCCCCC] dark:bg-faded/20 dark:border-0 text-black dark:text-white rounded-lg"
                        :class="{ 'border-transparent': draggedItem.id }"
                      >
                        <div
                          class="flex justify-between items-center mb-4 text-primary"
                        >
                          <div class="flex gap-1 items-center">
                            <MiniEyeIcon
                              class="w-5 h-5"
                              :class="[
                                draggedItem.id &&
                                !isMemberAlreadyAssignedToReviewers(
                                  draggedItem.id,
                                  review.id,
                                ) &&
                                draggedItem.type === DraggedItemType.member
                                  ? 'fill-green-500'
                                  : 'fill-primary',
                              ]"
                            />
                            <p class="font-medium text-sm text-black">
                              Reviewer
                            </p>
                          </div>
                          <div
                            v-tooltip="'Click to add reviewer'"
                            class="flex px-2 py-1 gap-1 hover:cursor-pointer items-center rounded-lg hover:bg-[#F2F6F8]"
                            @click="show"
                          >
                            <PlusIcon class="w-4 h-4 fill-primary" />
                            <p class="font-normal text-xs leading-[18px]">
                              Add reviewer
                            </p>
                          </div>
                        </div>
                        <div class="flex flex-wrap justify-start gap-2">
                          <div
                            v-for="user in review.reviewers"
                            :key="'reviewer-' + user.id"
                            v-tooltip="user?.email"
                          >
                            <div
                              class="max-w-fit rounded-full text-center border-2 border-transparent py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-default"
                              :style="{
                                'background-color': `${
                                  usersColors[user?.id ?? '']
                                }40`,
                              }"
                            >
                              <div
                                class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                                :style="{
                                  backgroundColor: usersColors[user?.id ?? ''],
                                }"
                              >
                                <p class="h-3 w-3 font-medium leading-3">
                                  {{ user?.email[0].toUpperCase() }}
                                </p>
                              </div>
                              <div class="leading-none truncate max-w-[48px]">
                                {{ user?.email }}
                              </div>
                              <div
                                class="w-2.5 h-2.5 flex items-center justify-center"
                              >
                                <button
                                  class="hover:bg-transparent"
                                  @click.stop="
                                    removeReviewerFromReview(
                                      review.id,
                                      user?.id!,
                                    )
                                  "
                                >
                                  <XMarkIcon class="h-[16.5px]" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <template v-if="draggedItem.id">
                          <div
                            v-if="
                              isMemberAlreadyAssignedToReviewers(
                                draggedItem.id,
                                review.id,
                              ) || draggedItem.type !== DraggedItemType.member
                            "
                            class="absolute top-0 left-0 w-full h-full rounded-lg bg-white bg-opacity-40 border border-solid border-[#D9E4EA]"
                          />
                          <DropZone
                            v-else
                            v-slot="{ isDraggedOver }"
                            @dropped="addReviewerToReview(review.id, $event)"
                          >
                            <div
                              class="absolute rounded-lg top-0 left-0 w-full h-full flex items-start justify-end px-2 py-[18px]"
                              :class="{
                                'bg-green-500 opacity-90': isDraggedOver,
                                'border-2 border-dashed !border-green-500 bg-green-500/10':
                                  !isDraggedOver,
                              }"
                            >
                              <div
                                v-if="isDraggedOver"
                                class="flex gap-1 items-center text-white pointer-events-none"
                              >
                                <EyePlusIcon class="w-5 h-5 fill-white" />
                                <p class="font-medium text-sm">
                                  Assign Reviewer
                                </p>
                              </div>
                            </div>
                          </DropZone>
                        </template>
                      </div></template
                    >
                  </ReviewMemberAssignment>
                </div>
                <div>
                  <ReviewMemberAssignment
                    :project="project"
                    :review-id="review.id"
                    :role="ReviewRole.APPROVER || ReviewRole.EXTERNALAPPROVERS"
                  >
                    <template #activator="{ show }">
                      <div
                        class="flex flex-col justify-stretch border px-2 py-4 relative bg-white border-[#CCCCCC] dark:bg-faded/20 text-black dark:text-white dark:border-0 rounded-lg"
                        :class="{ 'border-transparent': draggedItem.id }"
                      >
                        <div
                          class="flex justify-between items-center mb-4 text-primary"
                        >
                          <div class="flex gap-1 items-center">
                            <MiniHandThumbUpIcon
                              class="w-5 h-5"
                              :class="[
                                draggedItem.id &&
                                !isMemberAlreadyAssignedToApprovers(
                                  draggedItem.id,
                                  review.id,
                                ) &&
                                !isExternalMemberAlreadyAssignedToApprovers(
                                  draggedItem.id,
                                  review.id,
                                )
                                  ? 'fill-green-500'
                                  : 'fill-primary',
                              ]"
                            />
                            <p class="font-medium text-sm text-black">
                              Approvers
                            </p>
                          </div>
                          <div
                            v-tooltip="'Click to add approver'"
                            class="flex px-2 py-1 gap-1 hover:cursor-pointer items-center rounded-lg hover:bg-[#F2F6F8]"
                            @click="show"
                          >
                            <PlusIcon class="w-4 h-4 fill-primary" />
                            <p class="font-normal text-xs leading-[18px]">
                              Add approver
                            </p>
                          </div>
                        </div>

                        <div class="flex flex-wrap justify-start gap-2">
                          <div
                            v-for="user in review.approvers"
                            :key="'approver-' + user.id"
                            v-tooltip="user?.email"
                          >
                            <div
                              class="max-w-fit rounded-full text-black text-center border-2 border-transparent py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap"
                              :style="{
                                'background-color': `${
                                  usersColors[user?.id ?? '']
                                }40`,
                              }"
                            >
                              <div
                                class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                                :style="{
                                  backgroundColor: usersColors[user?.id ?? ''],
                                }"
                              >
                                <p class="h-3 w-3 font-medium leading-3">
                                  {{ user?.email[0].toUpperCase() }}
                                </p>
                              </div>
                              <div class="leading-none truncate max-w-[48px]">
                                {{ user?.email }}
                              </div>
                              <div
                                class="w-2.5 h-2.5 flex items-center justify-center"
                              >
                                <button
                                  class="hover:bg-transparent"
                                  @click.stop="
                                    removeApproverFromReview(
                                      review.id,
                                      user?.id!,
                                    )
                                  "
                                >
                                  <XMarkIcon class="h-[16.5px]" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            v-for="externalApprover in review.externalApprovers"
                            :key="'externalApprover-' + externalApprover"
                            v-tooltip="externalApprover?.name"
                          >
                            <div
                              class="bg-[#D8DCDE] max-w-fit rounded-full text-black text-center border-2 border-transparent py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap"
                            >
                              <div
                                class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center bg-primary/40"
                              >
                                <p
                                  class="h-3 w-3 font-medium leading-3 text-white"
                                >
                                  {{ externalApprover.name[0].toUpperCase() }}
                                </p>
                              </div>
                              <div class="leading-none truncate max-w-[48px]">
                                {{ externalApprover.name }}
                              </div>
                              <div
                                class="w-2.5 h-2.5 flex items-center justify-center"
                              >
                                <button
                                  class="hover:bg-transparent"
                                  @click.stop="
                                    removeExternalApproverFromReview(
                                      review.id,
                                      externalApprover.id,
                                    )
                                  "
                                >
                                  <XMarkIcon class="h-[16.5px]" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <template
                          v-if="
                            draggedItem.id &&
                            draggedItem.type === DraggedItemType.member
                          "
                        >
                          <div
                            v-if="
                              isMemberAlreadyAssignedToApprovers(
                                draggedItem.id,
                                review.id,
                              )
                            "
                            class="absolute top-0 left-0 w-full h-full rounded-lg bg-white bg-opacity-40 border border-solid border-[#D9E4EA]"
                          />

                          <DropZone
                            v-else
                            v-slot="{ isDraggedOver }"
                            @dropped="addApproverToReview(review.id, $event)"
                          >
                            <div
                              class="absolute rounded-lg top-0 left-0 w-full h-full flex items-start justify-end px-2 py-[18px]"
                              :class="{
                                'bg-green-500 opacity-90': isDraggedOver,
                                'border-2 border-dashed !border-green-500 bg-green-500/10':
                                  !isDraggedOver,
                              }"
                            >
                              <div
                                v-if="isDraggedOver"
                                class="flex justify-start gap-1 items-center text-white pointer-events-none"
                              >
                                <MiniHandThumbUpPlusIcon
                                  class="w-5 h-5 fill-white"
                                />
                                <p class="font-medium text-sm">
                                  Assign Approver
                                </p>
                              </div>
                            </div>
                          </DropZone>
                        </template>
                        <template
                          v-else-if="
                            draggedItem.id &&
                            draggedItem.type === DraggedItemType.externalMember
                          "
                        >
                          <div
                            v-if="
                              isExternalMemberAlreadyAssignedToApprovers(
                                draggedItem.id,
                                review.id,
                              )
                            "
                            class="absolute top-0 left-0 w-full h-full rounded-lg bg-white bg-opacity-40 border border-solid border-[#D9E4EA]"
                          />

                          <DropZone
                            v-else
                            v-slot="{ isDraggedOver }"
                            @dropped="
                              (externalMemberId) =>
                                addExternalApproverToReview(
                                  review.id,
                                  externalMemberId,
                                )
                            "
                          >
                            <div
                              class="absolute rounded-lg top-0 left-0 w-full h-full flex items-start justify-end px-2 py-[18px]"
                              :class="{
                                'bg-green-500 opacity-90': isDraggedOver,
                                'border-2 border-dashed !border-green-500 bg-green-500/10':
                                  !isDraggedOver,
                              }"
                            >
                              <div
                                v-if="isDraggedOver"
                                class="flex justify-start gap-1 items-center text-white pointer-events-none"
                              >
                                <MiniHandThumbUpPlusIcon
                                  class="w-5 h-5 fill-white"
                                />
                                <p class="font-medium text-sm">
                                  Assign Approver
                                </p>
                              </div>
                            </div>
                          </DropZone>
                        </template>
                      </div>
                    </template>
                  </ReviewMemberAssignment>
                </div>
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Project } from '@core/domain/models/project.model'
import { User } from '@core/domain/models/user.model'
import { computed, ref } from 'vue'
import Draggable from '../Draggable.vue'
import DropZone from '../DropZone.vue'
import Scrollbar from '@app/components/Global/Scrollbar.vue'
import UserPlusIcon from '@app/components/Icons/UserPlusIcon.vue'
import MiniUserIcon from '@app/components/Icons/MiniUserIcon.vue'
import MiniEyeIcon from '@app/components/Icons/MiniEyeIcon.vue'
import MiniHandThumbUpIcon from '@app/components/Icons/MiniHandThumbUpIcon.vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import ProjectExternalMembers from './ProjectMembers/ProjectExternalMembers.vue'
import ProjectMembers from './ProjectMembers/ProjectMembers.vue'
import EyePlusIcon from '@app/components/Icons/EyePlusIcon.vue'
import MiniHandThumbUpPlusIcon from '@app/components/Icons/MiniHandThumbUpPlusIcon.vue'
import ReviewMemberAssignment from './ReviewMemberAssignment.vue'
import PlusIcon from '@app/components/Icons/PlusIcon.vue'
import useProjects from '@app/views/Projects/use-projects'
import { ReviewRole } from '@core/domain/types/review-role.type'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import {
  InformationCircleIcon,
  LockClosedIcon,
} from '@heroicons/vue/24/outline'

const props = defineProps<{
  project: Project
}>()

const {
  addAuthorToReview,
  addReviewerToReview,
  addApproverToReview,
  addExternalApproverToReview,
  removeApproverFromReview,
  removeAuthorFromReview,
  removeReviewerFromReview,
  removeExternalApproverFromReview,
  removeProjectMember,
  removeProjectExternalMember,
} = useProjects()

function isMemberAlreadyAssignedToAuthors(userId: string, reviewId: number) {
  const review = props.project.reviews?.find((r) => r.id === reviewId)
  if (!review) return false
  return review.authors.some((a) => a.id === userId)
}

function isMemberAlreadyAssignedToReviewers(userId: string, reviewId: number) {
  const review = props.project.reviews?.find((r) => r.id === reviewId)
  if (!review) return false
  return review.reviewers.some((r) => r.id === userId)
}

function isMemberAlreadyAssignedToApprovers(userId: string, reviewId: number) {
  const review = props.project.reviews?.find((r) => r.id === reviewId)
  if (!review) return false
  return review.approvers.some((a) => a.id === userId)
}

function isExternalMemberAlreadyAssignedToApprovers(
  userId: string,
  reviewId: number,
) {
  const review = props.project.reviews?.find((r) => r.id === reviewId)
  if (!review) return false
  return review.externalApprovers.some((a) => a.id === userId)
}

enum DraggedItemType {
  member,
  externalMember,
}
const draggedItem = ref<{ id: string; type: DraggedItemType | undefined }>({
  id: '',
  type: undefined,
})

function updateDraggedItem(
  itemId: string,
  type: DraggedItemType,
  state: boolean,
) {
  if (state) {
    draggedItem.value.id = itemId
    draggedItem.value.type = type
  } else {
    draggedItem.value.id = ''
    draggedItem.value.type = undefined
  }
}

const availableColors = [
  '#A1CAE9',
  '#AFB5F1',
  '#C8AFF1',
  '#F6BBFB',
  '#F8AFD2',
  '#F8AFAF',
  '#F8C9AF',
  '#F8DFAF',
  '#EBE28F',
  '#C2F8AF',
  '#AFF8C3',
  '#AFF8EB',
  '#83D6E9',
  '#C8BFFF',
  '#FFBFDA',
  '#FFB6BA',
  '#FFC19E',
  '#D5DF9A',
  '#B8DF9A',
  '#9ADFAD',
  '#B1F1FF',
  '#84B5FF',
  '#8491FF',
  '#AA8CFF',
  '#FFB8FC',
  '#FF9595',
]

const usersColors = computed<{ [userId: string]: string }>(
  () =>
    props.project.users?.reduce(
      (a, u, i) => ({ ...a, [u.id]: availableColors[i] }),
      {},
    ) ?? {},
)

const canDeleteMember = () => {
  return true
}

const canDeleteExternalMember = () => {
  return true
}

function buildUserFullname(user: User) {
  if (!user) return ''
  let fullName = ''
  if (user.firstName) {
    fullName += user.firstName
  }
  if (user.lastName) {
    fullName += ' ' + user.lastName
  }
  if (!fullName) {
    fullName = user.email
  }
  return fullName
}
</script>
