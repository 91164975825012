import { StudyState } from '@app/types'
import { Review } from '@core/domain/models/review.model'
import { Search } from '@core/domain/models/search.model'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'

type ReviewItemScreening = {
  id: number
  title: string
  abstract?: string
  summary?: string
  reportNumber?: string
  reportDate?: string
  stateText: string
  fillColor: string
  type: ReviewItemType
}

export type SearchScreeningResult = {
  empty: boolean
  searchNumber: number
  source: string
  items: ReviewItemScreening[]
}

export function generateReviewScreeningResults(review: Review) {
  let searchIndex = 0
  const searchesResults: SearchScreeningResult[] = []

  const _getStudyStateText = (study: ReviewItem, studyState: StudyState) => {
    let studyStateText = '-'
    if (studyState === StudyState.DUPLICATE) {
      studyStateText = 'Duplicate'
    } else if (studyState === StudyState.INCLUDED) {
      studyStateText = 'Included'
    } else if (
      study.titleAndAbstractScreening &&
      study.titleAndAbstractScreening !== 'Included' &&
      review.plan?.screeningPlan?.titleAndAbstractCriteria?.length === 0
    ) {
      studyStateText = 'Excluded'
    } else if (
      study.titleAndAbstractScreening &&
      study.titleAndAbstractScreening !== 'Included'
    ) {
      studyStateText =
        'Excluded on title and abstract: ' + study.titleAndAbstractScreening
    } else if (
      study.fullTextScreening &&
      study.fullTextScreening != 'Included'
    ) {
      studyStateText = 'Excluded on full text: ' + study.fullTextScreening
    }
    return studyStateText
  }

  const _generateSearchScreeningResult = (
    search: Search,
    searchIndex: number,
  ) => {
    const items: ReviewItemScreening[] = []
    const source = search.source.name ?? '-'

    search.studies.forEach((s) => {
      const studyStateText = _getStudyStateText(s, s.state)
      const baseItem = {
        id: s.id,
        stateText: studyStateText,
        title: s.metadata.title ?? '-',
        type: s.type,
        fillColor: studyStateText === 'Included' ? 'E2EFD8' : '',
      }

      if (s.type === ReviewItemType.Article) {
        items.push({
          ...baseItem,
          abstract: s.metadata.abstract
            ? s.metadata.abstract
                .replace(/<[^>]+>/gm, '')
                .replace(/\s+/g, ' ')
                .trim()
                .replace(/^Abstract(.+?)/m, '')
            : 'not available',
        })
      } else if (s.type === ReviewItemType.Incident) {
        items.push({
          ...baseItem,
          summary: s.metadata.summary ?? 'not available',
        })
      }
    })

    searchesResults.push({
      empty: search.studies.length === 0,
      source: source,
      searchNumber: searchIndex,
      items: items,
    })
    return searchesResults
  }

  const sortedSearchs = [...review.searches!]

  sortedSearchs
    .filter((c) => [ImportSourceType.DATABASE].includes(c.source.type))
    .forEach((search) => {
      searchIndex += 1

      _generateSearchScreeningResult(search, searchIndex)
    })
  sortedSearchs
    .filter((c) => [ImportSourceType.OTHER_SOURCE].includes(c.source.type))
    .forEach((search) => {
      searchIndex += 1

      _generateSearchScreeningResult(search, searchIndex)
    })

  sortedSearchs
    .filter((c) => c.source.type === ImportSourceType.HAND_SEARCH)
    .forEach((search) => {
      searchIndex += 1
      _generateSearchScreeningResult(search, searchIndex)
    })

  sortedSearchs
    .filter((c) => c.source.type === ImportSourceType.CITATION_SEARCH)
    .forEach((search) => {
      searchIndex += 1
      _generateSearchScreeningResult(search, searchIndex)
    })

  return searchesResults.sort((a, b) => a.searchNumber - b.searchNumber)
}
