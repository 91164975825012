<template>
  <div
    class="border divide-y divide-black/10 rounded-lg bg-white border-black/30 flex flex-col items-center"
  >
    <template v-for="source in availableBuiltInImportSources" :key="source.id">
      <button
        v-tooltip.right="'Evidence metadata extraction technology'"
        class="flex justify-between items-center w-full py-2 px-4"
        :class="{
          'hover:bg-black/5': isPlanUnlocked,
          'cursor-not-allowed': !isPlanUnlocked,
        }"
        @click="
          isPlanUnlocked
            ? addImportSource({
                id: source.id,
                name: source.label,
                type: source.type,
                url: source.url,
                description: source.description,
              })
            : ''
        "
      >
        <div class="flex text-left items-center gap-2">
          <img :src="source.iconUrl" class="w-6 h-6" alt="source icon" />
          {{ source.label }}
        </div>
        <span>
          <EvidenceLogo class="w-4 h-4" />
        </span>
      </button>
    </template>
    <template v-if="isCuttingEdge">
      <button
        v-for="source in availableCustomSources"
        :key="slugify(source.name, { lower: true })"
        class="px-4 py-2 w-full text-left flex items-center gap-2"
        :class="{
          'hover:bg-black/5': isPlanUnlocked,
          'cursor-not-allowed': !isPlanUnlocked,
        }"
        @click="isPlanUnlocked ? addImportSource(source) : ''"
      >
        <img :src="source.iconUrl" class="w-6 h-6" alt="source icon" />
        {{ source.name }}
      </button>
    </template>

    <ExpansionPanel class="w-full" :model-value="isSourcesExpanded">
      <template #activator>
        <button
          v-tooltip.right="
            `<p>Databases consist of sources where searches can be replicated by anyone with access, ensuring consistent and verifiable results.</p><br/>
  <div class='flex gap-3'><div>
    <div><strong>Scientific literature database</strong> </div>
    <div><strong>Publisher database</strong> </div>
    <div><strong>Scientific journal</strong> </div>
    <div><strong>Registers</strong> </div>
    <div><strong>Registries</strong> </div>
  </div>
  <div>
    <div>EXAMPLES: PubMed, Cochrane CDSR</div>
    <div>EXAMPLES: Wiley, MAG Online</div>
    <div>EXAMPLES: Nature, Science</div>
    <div>EXAMPLES: ClinicalTrials.gov, DKRS</div>
    <div>EXAMPLES: MAUDE, BfArM</div>
    </div></div>`
          "
          class="flex justify-between items-center w-full py-2 px-4"
          :class="{
            'hover:bg-black/5': isPlanUnlocked,
            'cursor-not-allowed': !isPlanUnlocked,
          }"
          @click="isPlanUnlocked ? togglePanel('sources') : ''"
        >
          <div>Database</div>
          <InformationCircleIcon class="w-4 h-4 text-primary" />
        </button>
      </template>
      <template #default>
        <NewImportSourceBuilder
          :import-source-type="ImportSourceType.DATABASE"
        />
      </template>
    </ExpansionPanel>

    <ExpansionPanel class="w-full" :model-value="isOtherSourcesExpanded">
      <template #activator>
        <button
          v-tooltip.right="
            `<p>Other methods encompass sources where searches are not easily replicable, often due to varying search algorithms or personalized content delivery.</p><br/>
  <div class='flex gap-3'>
              <div>
    <div><strong>Search engine</strong> </div>
    <div><strong>Health organizations</strong> </div>
    <div><strong>Medical associations</strong> </div>
    <div><strong>Standards Organizations</strong> </div>
    <div><strong>Manufacturer websites</strong></div>
    <div><strong>Other online sources</strong> </div>
  </div>
  <div>
    <div>EXAMPLES: Google Scholar, Google</div>
    <div>EXAMPLES: WHO, NICE</div>
    <div>EXAMPLES: European Society of Cardiology</div>
    <div>EXAMPLES: ISO, IEC</div>
    <div> EXAMPLES: Medtronix, Baxters</div>
    <div> Any online source not included above</div>
    </div>
    </div>`
          "
          class="flex justify-between items-center w-full py-2 px-4"
          :class="{
            'hover:bg-black/5': isPlanUnlocked,
            'cursor-not-allowed': !isPlanUnlocked,
          }"
          @click="isPlanUnlocked ? togglePanel('other-sources') : ''"
        >
          <div>Other source</div>
          <InformationCircleIcon class="w-4 h-4 text-primary" />
        </button>
      </template>
      <template #default>
        <NewImportSourceBuilder
          :import-source-type="ImportSourceType.OTHER_SOURCE"
        />
      </template>
    </ExpansionPanel>
    <button
      v-if="!isCitationSearchActive"
      v-tooltip.right="
        `<p>Document records identified when screening the references of already identified records.</p>`
      "
      class="px-4 py-2 w-full text-left flex items-center justify-between gap-2"
      :class="{
        'hover:bg-black/5': isPlanUnlocked,
        'cursor-not-allowed': !isPlanUnlocked,
      }"
      @click="isPlanUnlocked ? addImportSource(citationSearch) : ''"
    >
      <div>Citation search</div>
      <InformationCircleIcon class="w-4 h-4 text-primary" />
    </button>

    <button
      v-if="!isHandSearchActive"
      v-tooltip.right="
        `<p>(not recommended) documents records that have not been captured through systematic searches</p>`
      "
      class="px-4 py-2 w-full text-left flex items-center justify-between gap-2"
      :class="{
        'hover:bg-black/5': isPlanUnlocked,
        'cursor-not-allowed': !isPlanUnlocked,
      }"
      @click="isPlanUnlocked ? addImportSource(handSearch) : ''"
    >
      <div>Hand search</div>
      <InformationCircleIcon class="w-4 h-4 text-primary" />
    </button>
  </div>
</template>
<script setup lang="ts">
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { computed, ref } from 'vue'
import NewImportSourceBuilder from './NewImportSourceBuilder.vue'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { DevToolboxKey } from '@app/injectionKeys'
import slugify from 'slugify'
import { isUrlValid } from '@app/utils/urlValidation'
import EvidenceLogo from '@app/components/Icons/EvidenceLogo.vue'

const response = await fetch('/customSources.json')
const customSources: {
  name: string
  url: string
  description: string
  type: ImportSourceType
  iconUrl?: string
}[] = await response.json()

const loading = useLoading()
const snackbar = useSnackbar()
const builtInImportSources = useBuiltInImportSources()

const review = injectStrict(ReviewKey)
const { isCuttingEdge } = injectStrict(DevToolboxKey)

const activeExpansionPanel = ref<'sources' | 'other-sources' | ''>('')

const isSourcesExpanded = computed(
  () => activeExpansionPanel.value === 'sources',
)
const isOtherSourcesExpanded = computed(
  () => activeExpansionPanel.value === 'other-sources',
)

const isPlanUnlocked = computed(() => !review.isPlanReadonly.value)

const handSearch = builtInImportSources.findById(
  BuiltInImportSourceId.HAND_SEARCH,
)!
const citationSearch = builtInImportSources.findById(
  BuiltInImportSourceId.CITATION_SEARCH,
)!

async function addImportSource(data: {
  id?: string
  name: string
  url?: string
  description?: string
  type: ImportSourceType
}) {
  if (
    data.type !== ImportSourceType.HAND_SEARCH &&
    data.type !== ImportSourceType.CITATION_SEARCH &&
    (!data?.url || !isUrlValid(data?.url ?? ''))
  ) {
    throw new Error('Invalid URL')
  }
  loading.start()
  try {
    await review.addCustomImportSourceToPlan({
      id: data.id,
      name: data.name,
      url: data.url ?? '',
      description: data.description,
      type: data.type,
    })
    snackbar.show(
      SnackbarState.SUCCESS,
      'Import source successfully added to plan',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

const availableBuiltInImportSources = computed(() => {
  const builtInSources = [
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.PUBMED)!.name,
      id: BuiltInImportSourceId.PUBMED,
      iconUrl: '/sources-icons/pubmed.png',
      type: builtInImportSources.findById(BuiltInImportSourceId.PUBMED)!.type,
      url: builtInImportSources.findById(BuiltInImportSourceId.PUBMED)!.url,
      description: builtInImportSources.findById(BuiltInImportSourceId.PUBMED)!
        .description,
    },
    {
      label: builtInImportSources.findById(
        BuiltInImportSourceId.GOOGLE_SCHOLAR,
      )!.name,
      id: BuiltInImportSourceId.GOOGLE_SCHOLAR,
      iconUrl: '/sources-icons/google-scholar.png',
      type: builtInImportSources.findById(BuiltInImportSourceId.GOOGLE_SCHOLAR)!
        .type,
      url: builtInImportSources.findById(BuiltInImportSourceId.GOOGLE_SCHOLAR)!
        .url,
      description: builtInImportSources.findById(
        BuiltInImportSourceId.GOOGLE_SCHOLAR,
      )!.description,
    },
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.DIMENSIONS_AI)!
        .name,
      id: BuiltInImportSourceId.DIMENSIONS_AI,
      iconUrl: '/sources-icons/dimensions-ai.png',
      type: builtInImportSources.findById(BuiltInImportSourceId.DIMENSIONS_AI)!
        .type,
      url: builtInImportSources.findById(BuiltInImportSourceId.DIMENSIONS_AI)!
        .url,
      description: builtInImportSources.findById(
        BuiltInImportSourceId.DIMENSIONS_AI,
      )!.description,
    },
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.COCHRANE)!
        .name,
      id: BuiltInImportSourceId.COCHRANE,
      iconUrl: '/sources-icons/cochrane.png',
      type: builtInImportSources.findById(BuiltInImportSourceId.COCHRANE)!.type,
      url: builtInImportSources.findById(BuiltInImportSourceId.COCHRANE)!.url,
      description: builtInImportSources.findById(
        BuiltInImportSourceId.COCHRANE,
      )!.description,
    },
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.MAUDE)!.name,
      id: BuiltInImportSourceId.MAUDE,
      iconUrl: '/sources-icons/maude.png',
      type: builtInImportSources.findById(BuiltInImportSourceId.MAUDE)!.type,
      url: builtInImportSources.findById(BuiltInImportSourceId.MAUDE)!.url,
      description: builtInImportSources.findById(BuiltInImportSourceId.MAUDE)!
        .description,
    },
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.OPEN_ALEX)!
        .name,
      id: BuiltInImportSourceId.OPEN_ALEX,
      iconUrl: '/sources-icons/openalex.png',
      type: builtInImportSources.findById(BuiltInImportSourceId.OPEN_ALEX)!
        .type,
      url: builtInImportSources.findById(BuiltInImportSourceId.OPEN_ALEX)!.url,
      description: builtInImportSources.findById(
        BuiltInImportSourceId.OPEN_ALEX,
      )!.description,
    },

    {
      label: builtInImportSources.findById(BuiltInImportSourceId.ICTRP)!.name,
      id: BuiltInImportSourceId.ICTRP,
      iconUrl: '/sources-icons/ictrp.png',
      type: builtInImportSources.findById(BuiltInImportSourceId.ICTRP)!.type,
      url: builtInImportSources.findById(BuiltInImportSourceId.ICTRP)!.url,
      description: builtInImportSources.findById(BuiltInImportSourceId.ICTRP)!
        .description,
    },

    ...(isCuttingEdge
      ? [
          {
            label: builtInImportSources.findById(BuiltInImportSourceId.PMC)!
              .name,
            id: BuiltInImportSourceId.PMC,
            iconUrl: '/sources-icons/pmc.png',
            type: builtInImportSources.findById(BuiltInImportSourceId.PMC)!
              .type,
            url: builtInImportSources.findById(BuiltInImportSourceId.PMC)!.url,
            description: builtInImportSources.findById(
              BuiltInImportSourceId.PMC,
            )!.description,
          },
          {
            label: builtInImportSources.findById(BuiltInImportSourceId.MDCG)!
              .name,
            id: BuiltInImportSourceId.MDCG,
            iconUrl: '/sources-icons/mdcg.png',
            type: builtInImportSources.findById(BuiltInImportSourceId.MDCG)!
              .type,
            url: builtInImportSources.findById(BuiltInImportSourceId.MDCG)!.url,
            description: builtInImportSources.findById(
              BuiltInImportSourceId.MDCG,
            )!.description,
          },
        ]
      : []),
  ]

  const reviewImportSources =
    review.entity.value.plan?.importPlan.importSources ?? []

  return builtInSources.filter(
    (source) =>
      !reviewImportSources.some(
        (reviewSource) => reviewSource.id === source.id,
      ),
  )
})

const availableCustomSources = computed(() => {
  const reviewImportSources =
    review.entity.value.plan?.importPlan.importSources ?? []
  return customSources.filter(
    (source) =>
      !reviewImportSources.some(
        (reviewSource) =>
          reviewSource.id === slugify(source.name, { lower: true }),
      ),
  )
})

const isHandSearchActive = computed(() => {
  return (
    review.entity.value.plan?.importPlan.importSources?.some(
      (x) => x.id === BuiltInImportSourceId.HAND_SEARCH,
    ) ?? false
  )
})

const isCitationSearchActive = computed(() => {
  return (
    review.entity.value.plan?.importPlan.importSources?.some(
      (x) => x.id === BuiltInImportSourceId.CITATION_SEARCH,
    ) ?? false
  )
})

function togglePanel(panel: 'sources' | 'other-sources') {
  if (activeExpansionPanel.value === panel) {
    activeExpansionPanel.value = ''
  } else {
    activeExpansionPanel.value = panel
  }
}
</script>
