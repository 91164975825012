<template>
  <ImportSourceCard
    :import-source="cenelec"
    :tooltip="{
      text: 'Use the Evidence browser extension to import studies from CENELEC',
      url: '/docs/reviews.html#cenelec',
    }"
    @import-source-removed="emit('importSourceRemoved')"
  >
  </ImportSourceCard>
</template>
<script setup lang="ts">
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import ImportSourceCard from '../Components/ImportSourceCard.vue'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'

const builtInImportSources = useBuiltInImportSources()

const cenelec = builtInImportSources.findById(BuiltInImportSourceId.CENELEC)!
const emit = defineEmits<(e: 'importSourceRemoved') => void>()
</script>
