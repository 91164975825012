<template>
  <FloatingMenu
    v-if="isAssistantConfigured"
    :offset="[0, 5]"
    placement="bottom"
  >
    <template #activator="{ show }">
      <button
        v-tooltip="'Generate answer'"
        class="bg-white rounded-lg p-1"
        @click.stop="show"
      >
        <ChatBubbleLeftEllipsisIcon
          v-if="!isGeneratingAnswer"
          class="w-5 h-5"
        />
        <span v-else class="loader w-5 h-5"></span>
      </button>
    </template>
    <template #content="{ hide }">
      <div class="p-4 flex flex-col items-start gap-2 w-[700px]">
        <div class="border border-black/10 rounded-md w-full flex">
          <div class="flex-1 p-3">
            {{ props.query }}
            <div class="flex-1">
              <InlineTextInput
                v-model="internalQuery"
                editor-classes="!p-2 max-h-[200px] overflow-auto"
                multiline
                placeholder="refine the query"
                @save="generateAnswer(query + ' ' + internalQuery)"
              />
            </div>
          </div>
          <button
            class="px-3 py-1 text-white rounded-lg self-stretch hover:bg-black/5"
            @click="generateAnswer(query + ' ' + internalQuery)"
          >
            <PaperAirplaneIcon class="text-primary w-5 h-5" />
          </button>
        </div>
        <div class="flex py-2 w-full">
          <div
            class="flex flex-col gap-1 px-1 mr-2 max-h-[250px] overflow-auto"
          >
            <div v-for="(qa, index) in answersHistory" :key="'qa-' + qa.id">
              <button
                class="border rounded-full w-5 h-5"
                :class="{
                  'bg-primary text-white border-transparent':
                    activeValueIndex === index,
                  'bg-green-600 text-white border-transparent':
                    diffValueIndex === index,
                }"
                @click.exact="
                  () => {
                    if (diffValueIndex === index) return
                    activeValueIndex = index
                  }
                "
                @click.ctrl.exact="
                  () => {
                    if (activeValueIndex === index) return
                    if (diffValueIndex === index) diffValueIndex = -1
                    else diffValueIndex = index
                  }
                "
              >
                {{ index + 1 }}
              </button>
            </div>
          </div>

          <div class="space-y-2 flex flex-col justify-stretch w-full">
            <div>
              <div class="text-sm">Query</div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-if="queryDiffHtml" v-html="queryDiffHtml"></p>
              <p v-else>{{ activeValue.question }}</p>
            </div>
            <div v-if="activeValue.answer" class="flex items-center rounded-lg">
              <div class="flex-1">
                <div class="text-sm mb-1 font-medium">Answer</div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-if="valueDiffHtml[0]" v-html="valueDiffHtml[0]"></p>
                <p v-else>
                  {{ activeValue.answer }}
                </p>
              </div>
              <button
                v-if="activeValue.answer !== value"
                class="px-2 border-l py-1 border-black/10 self-stretch rounded-r-lg hover:bg-black/5"
                @click="
                  () => {
                    useAnswer(activeValue.answer)
                    hide()
                  }
                "
              >
                <PaperAirplaneIcon class="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-row gap-2 text-xs text-black/80">
          AI models make mistakes. Always double check the information.
        </div>
      </div>
    </template>
  </FloatingMenu>
  <FloatingMenu v-else :offset="[0, 5]" placement="bottom" trigger="hover">
    <template #activator>
      <button class="bg-white rounded-lg p-1">
        <ChatBubbleLeftEllipsisIcon
          v-if="!isGeneratingAnswer"
          class="w-5 h-5"
        />
        <span v-else class="loader w-5 h-5"></span>
      </button>
    </template>
    <template #content>
      <div class="w-64">
        The AI model is not configured. Please configure it in the
        <RouterLink to="/settings" class="text-primary underline"
          >settings</RouterLink
        >.
      </div>
    </template>
  </FloatingMenu>
</template>
<script setup lang="ts">
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { computed, ref } from 'vue'
import { ReviewKey } from '../../../use-review'
import ChatBubbleLeftEllipsisIcon from '@app/components/Icons/ChatBubbleLeftEllipsisIcon.vue'
import InlineTextInput from '@app/components/Global/Inputs/InlineTextInput.vue'
import { Change, diffWordsWithSpace } from 'diff'
import { useStorage } from '@vueuse/core'
import { v4 as uuid } from 'uuid'
import PaperAirplaneIcon from '@app/components/Icons/PaperAirplaneIcon.vue'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import useAiAssistant from '@app/composables/useAiAssistant'
const props = withDefaults(
  defineProps<{
    modelValue: string
    study: ReviewItem
    attributeStructure: AttributeStructure
    query: string
  }>(),
  {
    query: '',
  },
)
type QuestionAnswer = {
  question: string
  answer: string
  id: string
}
const snackbar = useSnackbar()

const { isConfigured: isAssistantConfigured } = useAiAssistant()

const isGeneratingAnswer = ref(false)
const emit = defineEmits<(e: 'save', value: string) => void>()

const value = ref<string>(props.modelValue)
const answersHistory = useStorage<QuestionAnswer[]>(
  'answers-history-' + props.study.id + '' + props.attributeStructure.id,
  [],
)

const internalQuery = ref('')
const activeValueIndex = ref(0)

const activeValue = computed<QuestionAnswer>(
  () => answersHistory.value[activeValueIndex.value] ?? {},
)

const diffValue = computed(() => {
  return answersHistory.value[diffValueIndex.value]
})

const diffValueIndex = ref(-1)

const valueDiffHtml = computed(() => {
  if (!diffValue.value || !activeValue.value) return ''
  const diff1 = diffWordsWithSpace(
    activeValue.value.answer,
    diffValue.value.answer,
  )
  const formatDiff = (t: string, part: Change) => {
    let classes = 'text-black'
    if (part.added) classes = 'text-green-600'
    else if (part.removed) classes = 'text-red-600 line-through'
    t += `<span class="${classes}">${part.value}</span>`
    return t
  }

  return diff1.reduce(formatDiff, '')
})

const queryDiffHtml = computed(() => {
  if (!diffValue.value || !activeValue.value) return ''
  const diff = diffWordsWithSpace(
    activeValue.value.question,
    diffValue.value.question,
  )
  let html = ''

  diff.forEach((part) => {
    let classes = 'text-black'
    if (part.added) classes = 'text-green-600'
    else if (part.removed) classes = 'text-red-600 line-through'
    html += `<span class="${classes}">${part.value}</span>`
  })

  return html
})

const review = injectStrict(ReviewKey)
const loading = useLoading()

async function generateAnswer(question: string) {
  loading.start()
  isGeneratingAnswer.value = true
  try {
    const answer = await review.answerStudyQuestion(props.study.id, question)
    answersHistory.value.unshift({
      question: question,
      answer: answer.replace(/【.*?】/g, ''),
      id: uuid(),
    })
    snackbar.success('Answer generated successfully.')
    activeValueIndex.value = 0
  } catch (_) {
    snackbar.error('The AI model timed out. Please try again.')
  } finally {
    loading.stop()
    isGeneratingAnswer.value = false
  }
}

function useAnswer(answer: string) {
  value.value = answer
  emit('save', value.value)
}
</script>
